import React from "react";

export const About = (props) => {
  return (
    <div id="about">
              <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Sacrificed Goddesses</h2>
          <p1>The Burn Ritual Has Opened A Window To The Underworld</p1>
          </div>
          <iframe src="https://nfteyez.global/accounts/FV9ziGQ9d7kovSpuF6wMQdcdbEGSPhVpFwxtRmRYrWpp?collection=CkU4TnReUUtj1SeaBjEKdhaPW82KXYntYDQxQaEJp5nq" width="100%" height="100%"></iframe>
         
              <iframe src="https://nfteyez.global/accounts/3SL9qttaTYBkU8Ro85JHLvxnwN1y4atE2PPGssqbxFG1?collection=CkU4TnReUUtj1SeaBjEKdhaPW82KXYntYDQxQaEJp5nq" width="100%" height="100%"></iframe>
              </div>

  );
};
